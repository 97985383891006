import React from 'react'
import Link from 'redux-first-router-link'
import Menu from '@groovehq/internal-design-system/lib/components/Menu/Menu'
import { toBaseQueryId } from 'ducks/searches/utils/query'
import { usePinnedSearches } from 'ducks/pinnedSearch/hooks'
import SearchRawCount from '../SearchRawCount'

const PinnedSearchItems = () => {
  const { pinnedSearches, currentQueryId } = usePinnedSearches()

  return (
    <>
      {pinnedSearches.map(({ linkTo, queryId: fullQueryId, title }) => {
        const queryId = toBaseQueryId(fullQueryId)
        const active = queryId === currentQueryId

        return (
          <Menu.Item
            as={Link}
            to={linkTo}
            title={title}
            count={<SearchRawCount queryId={queryId} />}
            key={queryId}
            value={queryId}
            active={active}
            isSubMenuItem
          >
            {title}
          </Menu.Item>
        )
      })}
    </>
  )
}

export default PinnedSearchItems
